import foto1 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto1.jpg'
import foto2 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto2.jpg'
import foto3 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto3.jpg'
import foto4 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto4.jpg'
import foto5 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto5.jpg'
import foto6 from '../../images/img-body/contar-para-que-ocurra/sexta-ed/foto6.jpg'

let contar6taEd = [
    {
        id: 1,
        imgSrc: foto1,
        alt: 'contar para que ocurra cuarta edición',
    },
    {
        id: 2,
        imgSrc: foto2,
        alt: 'contar para que ocurra cuarta edición',
    },
    {
        id: 3,
        imgSrc: foto3,
        alt: 'contar para que ocurra cuarta edición',
    },
    {
        id: 4,
        imgSrc: foto4,
        alt: 'contar para que ocurra cuarta edición',
    },
    {
        id: 5,
        imgSrc: foto5,
        alt: 'contar para que ocurra cuarta edición',
    },
    {
        id: 6,
        imgSrc: foto6,
        alt: 'contar para que ocurra cuarta edición',
    }
]

export default contar6taEd; 